import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Content, { HTMLContent } from "../components/Content"

// eslint-disable-next-line
export const FluidFilmLabPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <section className="section section--gradient">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="section--artist">
              <PageContent className="about--description" content={content} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

FluidFilmLabPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func,
};

const FluidFilmLabPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <FluidFilmLabPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

FluidFilmLabPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FluidFilmLabPage

export const fluidFilmLabPageQuery = graphql`
  query FluidFilmLabPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`